$primary-color: #41abf3; //#A31B7C;
$disabled-color: #cccccc;
$skipped-color: #eae7e7;
$contrast-black: #212121;
$contrast-blue: #0b56f3;
$contrast-gray: #67757c;
$link-blue: #383990;

$event-calendar-primary-color: #3b6dac;
$title-gray: #f0f7fc;
$background-color: #eef5f9; //#e1e8ed;
$sidebar-background-color: #eeeeee; //#e1e8ed;
$text-gray: #67757c; //#979eab;
$text-dark-gray: #595d64; //#607d8b;
$event-button-color: #23c6a0;
$side-bar-btn-color: #5cbcff;
$trip-edit-btn-color: #41abf3;
$place-button-color: #eec30d;

$pinned-border-color: #23c6a0;

$navigator-text-bg-color: #f2dbeb;
$navigator-row-bg-color: #9bd53e;
$navigator-accordion-data-row-bg-color: #e6e6e6;

$background-red: #fe4445;
$background-dark-blue: #49659f;
$background-orange: #ffbb34;
$trip-orange: #ff8834;
$trip-orange1: #ef8d49;

$share-facebook: #3b6dac;
$google: #e15f4b;
$share-tweet: #00acee;
$share-email: #0e76e6;
$share-email-for-details: #6eb2e0;
$social-login: #f08d49;

$accommodation: #f70096;
$arts: #9bd53e;
$shopping: #0783eb;
$activity: #8808ec;
$dining: #ec8f07;
$outdoor: #0d8804;
$entertainment: #e5ce02;
$transportation: #50212e;
$other: #3d3c3c;

$ele-blue: #41abf3; //#26c6da;
$text-hover-blue: #5fd6e5;
$text-selected-black: #455a64;
$hover-bg: #f8f7f7;
$header-green: #58b79c;
$input-border: #ced4da;
$label: #99abb4;
$border-gray: #d7dfe3;
$border-blue: #bbe1f6;
