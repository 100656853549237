// .Savannah {
//   #imgoingcalendar-wrapper,
//   .cdk-overlay-container {
//     font-family: "Oswald", sans-serif;

//     a {
//       color: #3476d2 !important;
//       &:hover {
//         text-decoration: underline;
//       }
//     }

//     p {
//       font-size: unset;
//       line-height: normal;
//       color: unset !important;
//     }

//     ig-visitor .page-header h1 {
//       color: white;
//     }

//     ig-side-bar {
//       .mdc-button__label {
//         span {
//           letter-spacing: initial;
//         }
//       }
//     }

//     .text-gray-700 {
//       color: rgb(55 65 81) !important;
//     }
//     .text-gray-600 {
//       color: rgb(75 85 99) !important;
//     }
//     .text-gray-500 {
//       color: rgb(107 114 128) !important;
//     }
//     .text-gray-400 {
//       color: rgb(156 163 175) !important;
//     }

//     .text-white {
//       color: white !important;
//     }

//     mat-dialog-container {
//       ig-fancy-event-details-modal {
//         .ig-fancy-details-ticket {
//           .ig-event-category {
//             a {
//               color: rgb(55 65 81) !important;
//             }
//           }
//         }
//       }
//     }

//     ig-visitor-fancy-card {
//       p.text-white {
//         color: white !important;
//       }

//       p[title] {
//         font-size: large !important;
//       }
//     }

//     ig-fancy-event-details-modal {
//       .main-event-info {
//         ig-stars {
//           img {
//             top: -4.2px;
//           }
//         }
//       }
//     }

//     .nd_options_customizer_forms input[type="text"] {
//       background-color: #f5f5f5;
//     }
//   }

//   .vc_row .nd_options_text_align_center {
//     text-align: unset;
//   }

//   @media screen and ((max-width: 1199px)) {
//     .wpb_column.vc_column_container.vc_col-sm-12.vc_hidden-xs {
//       display: block !important;
//     }
//   }
// }

// // https://cadillacmichigan.com/area-events/
// .CadillacMI {
//   #main-header {
//     z-index: 999;
//     padding: inherit;
//     display: inherit;
//   }
//   @media (min-width: 1024px) {
//     header {
//       padding: inherit;
//       display: inherit;
//     }
//   }
// }
// // CadillacMI has a top=-500px on the html tag, causes the Google address autocomplete to be hidden
// .CadillacMI.cdk-global-scrollblock {
//   top: 0 !important;
// }
// // https://www.tourcayuga.com/events/
// .CayugaNY.cdk-global-scrollblock {
//   top: 0 !important;
// }

// // https://www.visitforgottonia.com/events/
// .MacombIL {
//   font-size: 16px;
//   header {
//     display: block;
//   }
//   #imgoingcalendar-wrapper {
//     min-height: 260px !important;
//   }
// }

// // https://visitshenandoahcounty.com/events/
// .ShenandoahCountyVA {
//   .cdk-overlay-container {
//     top: 0 !important;
//   }
// }

// // https://www.santeecoopercountry.org/events/daily-events-calendar/
// .SanteeCooperSC {
//   #all-site-wrapper {
//     .iv-layout.header {
//       .container {
//         max-width: initial;
//       }
//     }
//     .iv-layout {
//       .site-main {
//         .entry-content > div:first-child {
//           margin-left: 0 !important;
//           margin-right: 0 !important;

//           .ivan-custom-wrapper {
//             margin-bottom: 0;
//           }
//           .wpb_wrapper {
//             font-size: 20px;
//           }
//           // .wpb_text_column {
//           //   font-size: 20px;
//           // }
//         }
//       }
//     }
//   }
// }

// // https://thisiscooperstown.com/events
// .CooperstownNY {
//   #imgoingcalendar-wrapper {
//     margin-top: 50px;
//   }

//   --scrollbar-color: #917233;
// }

// // https://visitschohariecounty.com/events
// .SchoharieNY {
//   --scrollbar-color: #7f6183;

//   #imgoingcalendar-wrapper,
//   .cdk-overlay-container {
//     section {
//       padding: 0;
//     }
//   }
// }

// // https://events.visitjacksoncountyfla.com/events/
// // https://visit.explorerabun.com/events/
// // https://vc.visitwytheville.com/events/
// // https://ptcpassport.visitpeachtreecity.com/events/
// .eltdf-wrapper {
//   .eltdf-content {
//     .vc_column_container {
//       h1.eltdf-st-title {
//         font-size: 40px;
//       }
//     }
//   }
// }

// // https://visitbgohio.org/?page_id=3691&preview=true
// .BGOhio {
//   .post-content {
//     h1 {
//       text-align: center;
//       font-size: 3.5rem;
//       font-weight: 700;
//       font-family: sofia-pro, sans-serif;
//     }
//   }
// }

// // https://destinationpanamacity.com/events/
// .PanamaCityFL {
//   --scrollbar-color: #1b75bb;
// }

// // https://orangetourism.org/events/
// .OrangeCoNY {
//   .em .flatpickr-calendar,
//   body .pac-container {
//     z-index: 10000000 !important;
//   }
// }

// // // https://visitmasoncityiowa.com/events/
// // .MasonCityIA {
// //   font-size: 16px;
// // }
// // // https://www.conwayark.com/
// // .ConwayAR {
// //   font-size: 16px;
// // }

// // https://www.visitnsbfl.com/test-event
// .NewSmyrnaBeach {
//   height: auto;
//   body {
//     height: auto;
//   }

//   #imgoingcalendar-wrapper,
//   .cdk-overlay-container {
//     button[mat-menu-item] {
//       border-radius: 0;
//     }
//   }
// }

// // https://www.experiencecu.org/events/calendar-of-events
// .ChampaignCoIL {
//   #imgoingcalendar-wrapper,
//   .cdk-overlay-container {
//     svg {
//       position: initial;
//     }
//   }
// }

// // // https://visitpatrickcounty.org/
// // .PatrickCoVA {
// //   .site-container {
// //     .wp-block-search__button {
// //       background-image: url("https://visitpatrickcounty.org/wp-content/themes/visit-patrick-county-2023/assets/icons/utility/search.svg");
// //     }
// //   }
// // }

.Savannah {
  // to solve TravelBuddy on SavannahVVC home page get covered issue
  .wpb-content-wrapper {
    .vc_row.wpb_row.vc_row-fluid.vc_row-no-padding {
      z-index: 1000 !important;
    }
  }

  // menu dropdown font size
  .navigation .sub-menu li a:not(.button) {
    font-size: 62.5%;
    line-height: 0.8;
  }

  #imgoingcalendar-wrapper,
  .cdk-overlay-container {
    font-family: "Oswald", sans-serif;

    .ig-nested-title {
      font-weight: 500;
    }

    .ig-fancy-event-details {
      mat-checkbox {
        label {
          margin-bottom: 0;
          font-size: 16px;
        }
      }
    }

    ig-nearby-places {
      .ig-nested-title {
        font-family: Open Sans, sans-serif !important;
        font-weight: 300 !important;
      }
    }

    google-map {
      .gm-style-mtc {
        button {
          border-radius: 2px;
        }
      }
    }
  }
}

// https://cadillacmichigan.com/area-events/
// CadillacMI has a top=-500px on the html tag, causes the Google address autocomplete to be hidden
.CadillacMI.cdk-global-scrollblock {
  top: 0 !important;
}

// https://www.tourcayuga.com/events/
.CayugaNY.cdk-global-scrollblock {
  top: 0 !important;
}

// https://www.visitforgottonia.com/events/
.MacombIL {
  #imgoingcalendar-wrapper {
    min-height: 260px !important;
  }
}

// https://visitshenandoahcounty.com/events/
.ShenandoahCountyVA {
  .cdk-overlay-container {
    top: 0 !important;
  }
}

// https://thisiscooperstown.com/events
.CooperstownNY {
  #imgoingcalendar-wrapper {
    margin-top: 50px;
  }

  --scrollbar-color: #917233;
}

// https://visitschohariecounty.com/events
.SchoharieNY {
  --scrollbar-color: #7f6183;
}

// https://destinationpanamacity.com/events/
.PanamaCityFL {
  --scrollbar-color: #1b75bb;

  .footer-logos a {
    display: inline-block;
  }
}

// https://orangetourism.org/events/
.OrangeCoNY {
  .em .flatpickr-calendar,
  body .pac-container {
    z-index: 10000000 !important;
  }
}

// https://www.visitnsbfl.com/test-event
.NewSmyrnaBeach {
  height: auto;

  body {
    height: auto;
  }
}

.AddisonTX {
  #block-menu-menu-tourism ul.menu li.top-level > a {
    font-size: 40px;
    line-height: 1em;
  }

  #block-menu-menu-tourism ul li ul li a {
    font-size: 20px;
  }

  #block-search-form form input[type="text"] {
    font-size: 16px;
  }

  #block-search-form form input[type="submit"] {
    top: 4px;
  }

  .footer-wrapper a,
  .footer-wrapper a.active,
  .footer-wrapper p {
    font-size: 20px;
  }
}

.CovingtonGA {
  .cdk-overlay-container {
    button.mat-mdc-menu-item {
      background-color: white;
    }
  }
}

// https://www.visitwestvolusia.com/camping/
.WestVolusiaFL {
  // .header-announcement-bar-wrapper {
  //   .header-display-desktop {
  //     display: block;

  //     .header-title-nav-wrapper {
  //       height: 70px;
  //       width: 100%;
  //     }

  //     .header-title {
  //       position: absolute;
  //       top: 0;
  //     }
  //   }

  //   .header-layout-nav-center .header-nav .header-nav-item {
  //     font-size: 17px;
  //   }

  //   .header-actions header-actions--right {
  //     display: none;
  //   }

  //   .header-layout-nav-center .header-title {
  //     width: auto;
  //   }

  //   .header-nav-wrapper {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }
  // }

  // .page-section.vertical-alignment--middle:not(.content-collection):not(.gallery-section):not(
  //     .user-items-list-section
  //   ):not(.editmode-changing-rowcount).section-height--medium
  //   > .content-wrapper {
  //   padding: 115px 0 0;
  // }

  // .content-wrapper {
  //   .sqsrte-large,
  //   p,
  //   .sqsrte-small {
  //     margin: 12px 0;
  //   }

  //   .sqsrte-large {
  //     font-size: 18px;
  //   }

  //   h6 {
  //     font-size: 14px;
  //   }
  //   h5 {
  //     font-size: 18px;
  //   }
  //   h4 {
  //     font-size: 22px;
  //   }
  //   h3 {
  //     font-size: 26px;
  //   }
  //   h2 {
  //     font-size: 32px;
  //   }

  //   h1 {
  //     font-size: 50px;
  //   }
  // }
}

// https://visitrichmondky.com/events-upcoming-iti/
// https://visitrichmondky.com/events-iti/
.RichmondKY {
  .cdk-overlay-container {
    ig-powered-by {
      img {
        width: 100px;
      }
    }
  }
}

// https://www.visitgrandprairietx.com/Deals/test-page
.GrandPrairieTX {
  .footer-container {
    .grid,
    img {
      display: initial;
    }
  }
}

// https://www.visithowardcounty.com/events/
.HowardCoMD {
  .ig-date-range-picker {
    .mat-date-range-input-mirror {
      line-height: 37px;
    }
  }
}

// https://cartersvillevisitorcenter.org/events/
// https://experienceherkimercounty.com/events/
// https://events.visitjacksoncountyfla.com/events/
// https://ptcpassport.visitpeachtreecity.com/events/
// https://vc.visitwytheville.com/events/
.CartersvilleGA,
.HerkimerCoNY,
.JacksonCoFL,
.PeachtreeCityGA,
.WythevilleVA {
  .cdk-overlay-container .ig-date-range-picker input {
    font-size: 16px;
  }
}

// https://ttw.lyd.mybluehost.me/events/
.EurekaCA {
  #imgoingcalendar-wrapper,
  .cdk-overlay-container {
    .items-center {
      visibility: visible !important;
    }

    .ig-fancy-event-details a {
      color: #3476d2;
    }
  }
}

// https://visitsumnertn.com/shop/
.SumnerTN {
  .cdk-overlay-container {
    button.mat-mdc-menu-item {
      background-color: white;
    }
  }
}

// https://discoverclermont.com/events/
.ClermontCoOH {
  #imgoingcalendar-wrapper,
  .cdk-overlay-container {
    .fixed {
      background-color: inherit;
    }
  }
}

// https://visitkingsport.com/event-calendar/
// fix scrollbar disappear issue
.KingsportTN {
  #main-content {
    .et_pb_code_inner {
      padding-right: 17px;
    }
  }
}

// https://experiencecu.org/events/calendar-of-events
// fix mobile view date pick style
.ChampaignCoIL {
  .cdk-overlay-container {
    @media (max-width: 767px) {
      mat-datepicker-content {
        table tr {
          display: table-row !important;

          td {
            width: 14.2857% !important;
          }
        }
      }
    }
  }
}
